import { UserTermsOfService } from "@yardzen/components/user-terms-of-service";
import { identifyLogRocketUser } from "@yardzen/next-client-util";
import { Session } from "next-auth";
import { useSession } from "next-auth/react";
import { ReactNode } from "react";

interface MainContentProps {
  isLeadCapture: boolean;
  mustAcceptTerms: boolean;
  userId: string | undefined;
  children: ReactNode;
}

export default function MainContent({
  isLeadCapture,
  mustAcceptTerms,
  userId,
  children,
}: MainContentProps) {
  const { data: session } = useSession() as {
    data: Session & { user: { id?: string; email?: string } };
    status: "authenticated" | "loading" | "unauthenticated";
  };
  if (session?.user?.id && session?.user?.email) {
    identifyLogRocketUser(session.user.id, session.user.email);
  }

  const showTermsOfService = mustAcceptTerms && userId;

  return showTermsOfService ? (
    <UserTermsOfService userId={userId} />
  ) : (
    <div id="root">
      <div className="min-h-[calc(100%-56px)]">{children}</div>
    </div>
  );
}
