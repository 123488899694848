"use client";

import { ApolloProvider } from "@apollo/client";
import { GoogleTagManager } from "@next/third-parties/google";
import { APIProvider } from "@vis.gl/react-google-maps";
import { LogRocket } from "@yardzen/components/log-rocket";
import { YzThemeProvider } from "@yardzen/components/theme-provider";
import { GQLClient, startFrontEndTracing } from "@yardzen/next-client-util";
import { SessionProvider } from "next-auth/react";
import { ReactNode } from "react";
import { ClientAuthChecker } from "./ClientAuthChecker";
import MainContent from "./MainContent";
if (typeof window !== "undefined") {
  startFrontEndTracing(
    "build-marketplace-web",
    process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT,
  );
}

const client = GQLClient();
export default function ClientsideLayout(props: {
  isLeadCapture: boolean;
  mustAcceptTerms: boolean;
  userId: string | undefined;
  children: ReactNode;
}) {
  return (
    <>
      <GoogleTagManager
        gtmId="GTM-NGDTCSR"
        gtmScriptUrl="https://yardwork.yardzen.com/gtm.js"
      />

      <APIProvider
        apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY!}
        onLoad={() => console.log("Google Maps API has loaded.")}
      >
        <LogRocket appName="build-marketplace" />
        <SessionProvider refetchInterval={60} refetchOnWindowFocus>
          <ClientAuthChecker />
          <ApolloProvider client={client}>
            <YzThemeProvider>
              <MainContent
                userId={props.userId}
                isLeadCapture={props.isLeadCapture}
                mustAcceptTerms={props.mustAcceptTerms}
              >
                {props.children}
              </MainContent>
            </YzThemeProvider>
          </ApolloProvider>
        </SessionProvider>
      </APIProvider>
    </>
  );
}
